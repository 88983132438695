@font-face {
  font-family: 'Playfair Display';
  src: url('PlayfairDisplay-Regular.eot');
  src: url('PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('PlayfairDisplay-Regular.woff2') format('woff2'),
    url('PlayfairDisplay-Regular.woff') format('woff'),
    url('PlayfairDisplay-Regular.ttf') format('truetype'),
    url('PlayfairDisplay-Regular.svg#PlayfairDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('BrandonGrotesque-Light.eot');
  src: url('BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
    url('BrandonGrotesque-Light.woff2') format('woff2'),
    url('BrandonGrotesque-Light.woff') format('woff'),
    url('BrandonGrotesque-Light.ttf') format('truetype'),
    url('BrandonGrotesque-Light.svg#BrandonGrotesque-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('BrandonGrotesque-Bold.eot');
  src: url('BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
    url('BrandonGrotesque-Bold.woff2') format('woff2'),
    url('BrandonGrotesque-Bold.woff') format('woff'),
    url('BrandonGrotesque-Bold.ttf') format('truetype'),
    url('BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('BrandonGrotesque-LightItalic.eot');
  src: url('BrandonGrotesque-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('BrandonGrotesque-LightItalic.woff2') format('woff2'),
    url('BrandonGrotesque-LightItalic.woff') format('woff'),
    url('BrandonGrotesque-LightItalic.ttf') format('truetype'),
    url('BrandonGrotesque-LightItalic.svg#BrandonGrotesque-LightItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('BrandonGrotesque-Regular.eot');
  src: url('BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
    url('BrandonGrotesque-Regular.woff2') format('woff2'),
    url('BrandonGrotesque-Regular.woff') format('woff'),
    url('BrandonGrotesque-Regular.ttf') format('truetype'),
    url('BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('BrandonGrotesque-Medium.eot');
  src: url('BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
    url('BrandonGrotesque-Medium.woff2') format('woff2'),
    url('BrandonGrotesque-Medium.woff') format('woff'),
    url('BrandonGrotesque-Medium.ttf') format('truetype'),
    url('BrandonGrotesque-Medium.svg#BrandonGrotesque-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}
